import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import contactStyles from "./contact.module.css"
// import chairImage from "../images/chair-contactpage.png"

const ContactPage = ({ data }) => (
    <Layout>
        <SEO title="Contact" />
        <div className={contactStyles.backgroundColor}></div>
        <div className={contactStyles.contactSection}>
            <Img fluid={data.beerWordImage.childImageSharp.fluid} alt="" className={contactStyles.beerWordImage} />
            <div className={contactStyles.contactContent}>
                <div className={contactStyles.contactAddress}>
                    <h2>FIND US</h2>
                    <address className={contactStyles.address}>
                        1802 Laporte Ave.<br />
                        Fort Collins, CO 80521
                    </address>
                </div>
                <div className={contactStyles.contactEmail}>
                    <h2>EMAIL</h2>
                    <address className={contactStyles.address}>
                        <a href="mailto:info@stodgybrewing.com" className={contactStyles.email}>info@stodgybrewing.com</a>
                    </address>
                </div>
                {/* <div className={contactStyles.contactPhone}>
                    <h2>CALL US</h2>
                    <a href="tel:+19702329702">(970) 232-9702</a>
                </div> */}
                <div className={contactStyles.contactPositions}>
                    <h2>POSITIONS</h2>
                    <p>Not hiring at this time.</p>
                    {/* <p><Link to="/hiring/">Hiring Part-Time Bartender</Link></p> */}
                </div>
                <div className={contactStyles.contactSocial}>
                    <h2>SOCIAL</h2>
                    <a href="https://www.facebook.com/profile.php?id=61561583229119" alt="Link to Facebook page" target="_blank"
                        rel="noreferrer noopener" className={contactStyles.socialLink}>Facebook</a>
                    <a href="https://www.instagram.com/stodgybrewing" alt="Link to Instagram page" target="_blank"
                        rel="noreferrer noopener" className={contactStyles.socialLink}>Instagram</a>
                </div>
            </div>
        </div>
        <div className={contactStyles.contactReservation}>
            <h2>RESERVATIONS</h2>
            <p>For parties of 15 or less we have a reservable private room. For larger groups we have a rentable back patio. Email us for availability and details.</p>
        </div>
        <a href="https://www.toasttab.com/stodgybrewing/giftcards"><div className={contactStyles.subscriptionButton}>BUY E-GIFT CARD</div></a>

    </Layout>
)

export const query = graphql`
  query {
    beerWordImage: file(relativePath: { eq: "beer_word.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ContactPage
